<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      color="dark"
      :closeOnBackdrop="false"
      title="DOCUMENTACION ESTANDAR"
      size="lg"
      :show.sync="ModModal"
    >
      <!--  INPUT TIPO DROP-->
      <CCol sm="12">
        <div
          class="flex items-center justify-center w-full h-screen text-center"
        >
          <div
            class="p-12 bg-gray-100 border border-gray-300"
            @dragover="dragover"
            @dragleave="dragleave"
            @drop="drop"
          >
            <div class="ocultar">
              <input
                type="file"
                multiple
                name="fields[assetsFieldHandle][]"
                id="assetsFieldHandle"
                @change="onChange"
                ref="file"
                accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              />
            </div>
            <label for="assetsFieldHandle" class="block">
              <img src="../../../public/img/download1.png" alt="donwload" />
              <h5>Elige un archivo o arrástralo aquí</h5>
            </label>
            <ul class="mt-4" v-if="this.filelist.length" v-cloak>
              <li class="text-sm p-1" v-for="file in filelist" :key="file.item">
                {{ file.name
                }}<button
                  class="ml-2"
                  type="button"
                  @click="remove(filelist.indexOf(file))"
                  title="Remove file"
                >
                  Eliminar
                </button>
              </li>
            </ul>
          </div>
        </div>
        <!-- FINAL DEL INPUT FILE -->
        <!-- BOTON DE AGREGAR + -->

        <div class="card-header" align="right">
          <template>
            <td class="center-cell">
              <div>
                <CButton
                  shape="square"
                  color="success"
                  size="sm"
                  v-c-tooltip="'Añadir archivo'"
                  class="d-flex align-items-center ml-3"
                  :disabled="isDisabled"
                  @click="guardar"
                >
                  <CIcon name="cil-plus" />
                </CButton>
              </div>
            </td>
          </template>
        </div>
      </CCol>
      <CCol sm="12">
        <dataTableExtended
     class="align-center-row-datatable"
          :items="itemsDocs"
          :fields="fields"
          column-filter
          :items-per-page="5"
          :noItemsView="tableText.noItemsViewText"
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          hover
          small
          sorter
          pagination
        >
          <template #DocsRoute="{item}">
            <td class="py-2">
              <a
                class="btn btn-sm btn-primary mr-1"
                v-c-tooltip="'Ver'"
                target="_blank"
                :href="item.DocsRoute"
              >
                <CIcon name="cil-search" />
              </a>

              <CButton
                color="danger"
                square
                size="sm"
                class="mr-1"
                v-c-tooltip="'Quitar'"
                @click="eliminar(item)"
              >
                <CIcon name="cil-minus" />
              </CButton>
            </td>
          </template>
        </dataTableExtended>
      </CCol>
      <template #footer>
        <CButton color="success" @click="CerrarLimpiar">
          <CIcon name="checkAlt" />&nbsp; ACEPTAR
        </CButton>
        <CButton color="dark" @click="CerrarLimpiar">
          <CIcon name="cil-chevron-circle-left-alt" />&nbsp; CANCELAR
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import { DateFormater } from "@/_helpers/funciones";
import General from "@/_mixins/general";
import UpperCase from "@/_validations/uppercase-directive";
import EstandarVali from "@/_validations/estandares/estandarVali";

const fields = [
  {
    key: "Nro",
    label: "#",
    _style: "width:1%;",
  },
  { key: "StandardDocumentDocRoute", label: "DOCUMENTO" },
  { key: "Usuario", label: "USUARIO" },
  { key: "Fecha", label: "FECHA" },
  {
    key: "DocsRoute",
    label: "",
    sorter: false,
    filter: false,
  },
];

function isDisabled() {
  return this.$v.$invalid;
}

const tableTextHelpers = {
  tableFilterText: {
    label: "FILTRAR:",
    placeholder: "INGRESE BUSQUEDA ",
  },
  itemsPerPageText: {
    label: "DOCUMENTOS POR PAGINA",
  },
  noItemsViewText: {
    noResults: "NO SE HAN ENCOTRADO RESULTADOS",
    noItems: "NO HAY REGISTROS DISPONIBLES",
  },
};

//METHOD
function CerrarLimpiar() {
  this.StandardDocumentDescription = "";
  this.StandardDocumentName = "";
  this.StandardOrganization = "";
  this.StandardDocumentDocRoute = "";
  this.filelist = "";
  this.ModModal = false;
}

function limpiarDatos() {
  this.StandardDocumentDescription = "";
  this.StandardDocumentName = "";
  this.StandardOrganization = "";
  this.StandardDocumentDocRoute = "";
  this.filelist = "";
}

function eliminar(item) {
  this.$swal
    .fire({
      text: `¿Esta seguro de quitar el elemento ${item.Nro} de la lista ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "CONFIRMAR",
      cancelButtonText: "CANCELAR",
    })
    .then((result) => {
      if (result.isConfirmed) {
        this.itemsDocs.splice(item, 1);
      }
    });
}

function submitFile() {
  this.Loading = true;
  let formData = new FormData();
  formData.append("file1", this.StandardDocumentDocRoute);
  this.$http
    .post("StandardDocument-insert", formData)
    .then((response) => {
      this.StandardDocumentDocRoute = response.data.data.files[0].path;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => (this.Loading = false));
}

function guardar() {
  if (this.$v.$invalid) return false;
  this.Loading = true;
  let res = [];
  let metodo = "";
  let ruta = "";
  let StandardDocumentJson = [];
  //ASIGNACION DE VARIABLES PARA GUARDAR
  StandardDocumentJson = {
    StandardDocumentId: this.StandardDocumentId,
    StandardDocumentName: this.StandardDocumentName,
    StandardDocumentDescription: this.StandardDocumentDescription,
    StandardOrganization: this.StandardOrganization,
    StandardDocumentDocJson: [
      {
        StandardDocumentDocRoute:
          typeof this.StandardDocumentDocRoute.name == "string"
            ? ""
            : this.StandardDocumentDocRoute,
      },
    ],
    Status: this.Status,
  };
  metodo = "PUT";
  ruta = "StandardDocument-update";

  //ENVIANDO POST PARA GUARDAR O ACTUALIZAR
  this.$http
    .ejecutar(metodo, ruta, StandardDocumentJson, {
      root: "StandardDocumentJson",
    })
    .then((response) => {
      res = [...response.data.data];
      this.limpiarDatos();
      this.$notify({
        group: "container",
        title: "¡Exito!",
        text: res[0].Response,
        type: "success",
      });
      this.listarDocs(this.StandardDocumentId);
      this.Loading = false;
      this.$emit("child-refresh", true);
      //this.ModModal = false;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    });
}

function listarDocs(StandardDocumentId) {
  this.itemsDocs = [];
  this.Loading = true;
  let listado = [];
  let listado2 = [];
  this.$http
    .get("StandardDocument-by-id", {
      StandardDocumentId: StandardDocumentId,
    })
    .then((response) => {
      listado2 = [...response.data.data];
      listado = listado2[0].StandardDocumentDocJson;
      let Nro = 1;
      this.itemsDocs = listado.map((listado) =>
        Object.assign({}, this.itemsDocs, {
          Nro: Nro++,
          StandardDocumentDocRoute: listado2[0].StandardDocumentName,
          DocsRoute: listado.StandardDocumentDocRoute
            ? `${
                this.$store.getters["connection/getBase"]
              }${listado.StandardDocumentDocRoute.replace("Public/", "", "i")}`
            : "N/A",
          Usuario: listado2[0].TransaLogin ? listado2[0].TransaLogin : "N/A",
          Fecha: listado2[0].TransaRegDate
            ? DateFormater.formatOnlyDateWithSlash(listado2[0].TransaRegDate)
            : "N/A",
        })
      );
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.Loading = false;
    });
}

function data() {
  return {
    //MODELO
    StandardDocumentDocRoute: "",
    StandardDocumentDocJson: "",
    StandardDocumentName: "",
    StandardOrganization: "",
    StandardDocumentDescription: "",

    //VARIABLES
    itemsDocs: [],
    ModModal: false,
    Loading: false,
    filelist: [],
    tableText: tableTextHelpers,
    Status: 1,
    selectOptions: [
      {
        value: 1,
        label: "ACTIVO",
      },
      {
        value: 0,
        label: "INACTIVO",
      },
    ],
  };
}

export default {
  name: "modif-modal",
  mixins: [General],
  data,
  props: {
    modal: null,
    fields: {
      type: Array,
      default() {
        return fields;
      },
    },
  },
  computed: {
    isDisabled,
  },
  methods: {
    listarDocs,
    guardar,
    submitFile,
    eliminar,
    limpiarDatos,
    CerrarLimpiar,
    //TRATAR DE MEJORAR ESTOS METODOS DEL INPUT FILE
    onChange() {
      this.filelist = [...this.$refs.file.files];
      this.StandardDocumentDocRoute = this.filelist[0];
      this.submitFile();
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
  },
  directives: UpperCase,
  validations: EstandarVali,
  watch: {
    modal: function() {
      if (this.modal) {
        this.ModModal = true;
        this.listarDocs(this.modal.StandardDocumentId);
        this.StandardDocumentId = this.modal.StandardDocumentId;
        this.StandardDocumentName = this.modal.Name;
        this.StandardDocumentDescription = this.modal.StandardDocumentDescription;
        this.StandardOrganization = this.modal.StandardOrganization;
        this.$emit("cerrarModal");
      }
    },
  },
};
</script>
<style scoped>
.ocultar {
  display: none;
}
.block {
  cursor: pointer;
}
</style>
